// import { values } from "core-js/core/array";
import Repository, {baseUrl} from './repository';


export async function generalSettings() {
    return await Repository.get(`${baseUrl}/general/settings`,{
        headers: {
            'channel': 'WOF'
        }
    })
        .then(result => {
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function generalPage(lang, link) {
    return await Repository.get(`${baseUrl}/general/page/${lang}/${link}`,{
        headers: {
            'channel': 'WOF'
        }
    })
        .then(result => {
            console.log(result);
            return result
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function generalLanguages() {
    return await Repository.get(`${baseUrl}/general/languages`,{
        headers: {
            'channel': 'WOF'
        }
    })
        .then(result => {
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}


//------------- USER DATA/LOGIN --------------


export async function heStart() {
    return await Repository.post(`${baseUrl}/heStart`,null,{
        headers: {
            'channel': 'WOF'
        }
    })
        .then(result => {
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function heEnd(trx) {
    return await Repository.get(`${baseUrl}/heEnd?trxId=${trx}`,{
        headers: {
            'channel': 'WOF'
        }
    })
        .then(result => {
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function userStatus(val) {
    return await Repository.get(`${baseUrl}/user/status?msisdn=${val}`,{
        headers: {
            'channel': 'WOF'
        }
    })
        .then(result => {
            console.log(result.data)
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}


export async function userOtp(msisdn) {
    return await Repository.post(`${baseUrl}/user/otp`,
        {
            "msisdn": `${msisdn}`
        },{
            headers: {
                'channel': 'WOF'
            }
        }
    )
        .then(result => {
            console.log('userOtp', result.data.data.transaction);
            return result
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function userVerify(val, val2) {
    return await Repository.post(`${baseUrl}/user/verify`, null, {
        headers: {
            'transaction': `${val}`,
            'otp': `${val2}`,
            'channel': 'WOF'
        }
    })
        .then(result => {
            console.log('userVerify', result.data);
            return result
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function userMe(val) {
    return await Repository.get(`${baseUrl}/user/me`, {
        headers: {
            'token': `${val}`,
            'channel': 'WOF'
        }
    })
        .then(result => {
            if (result.data.code === 401) {
                localStorage.removeItem('token');
                location.href = '/';
            }
            return result
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function userProfileUpdate(val1, val2, val3, val4) {
    return await Repository.post(`${baseUrl}/user/profile/update`,
        {
            "name": `${val1}`,
            "birthday": `${val2}`,
            "gender": `${val3}`
        },
        {
            headers: {
                'token': `${val4} `,
                'channel': 'WOF'
            }
        })
        .then(result => {
            console.log('userProfileUpdate', result)
            return result
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function userImage(token, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return await Repository.post(`${baseUrl}/user/profile/photo`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "token": token,
            'channel': 'WOF'
        },
        onUploadProgress
    }).then(response => {
        console.log('userImage', response)
        return response;
    }).catch(error => {
        console.log(JSON.stringify(error));
        return {data: {code: 2, message: error.message}};
    });
}

export async function setUserLang(val1, val2) {
    return await Repository.post(`${baseUrl}/user/profile/language?languageId=${val1}`, null, {
        headers: {
            'token': `${val2}`,
        'channel': 'WOF'
        }
    })
        .then(result => {
            console.log('userLang', result.data);
            return result
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

//------------- WOF --------------


export async function getWOFStatus(token) {

    /* return {
         "code": 200, "message": "OK", "t": token, "data": {
             "prizeList": [{
                 "id": 755735898,
                 "name": "2000 MB",
                 "productType": "package",
                 "productId": 755735898,
                 "is_purchasable": false,
                 "photo": "wof-pics/f/en/755735898.png"
             }, {
                 "id": 755735884,
                 "name": "3,000 MB for 5 GEL",
                 "productType": "package",
                 "productId": 755735884,
                 "is_purchasable": true,
                 "photo": "wof-pics/p/en/755735884.png"
             }, {
                 "id": 755735894,
                 "name": "500 MB",
                 "productType": "package",
                 "productId": 755735894,
                 "is_purchasable": false,
                 "photo": "wof-pics/f/en/755735894.png"
             }, {
                 "id": 755735877,
                 "name": "1,000 minutes for 12 GEL",
                 "productType": "package",
                 "productId": 755735877,
                 "is_purchasable": true,
                 "photo": "wof-pics/p/en/755735877.png"
             }, {
                 "id": 755735857,
                 "name": "Unlimited minutes and internet for 33 GEL",
                 "productType": "package",
                 "productId": 755735857,
                 "is_purchasable": true,
                 "photo": "wof-pics/p/en/755735857.png"
             }, {
                 "id": 755735913,
                 "name": "20 min",
                 "productType": "package",
                 "productId": 755735913,
                 "is_purchasable": false,
                 "photo": "wof-pics/f/en/755735913.png"
             }, {
                 "id": 755735923,
                 "name": "60 min",
                 "productType": "package",
                 "productId": 755735923,
                 "is_purchasable": false,
                 "photo": "wof-pics/f/en/755735923.png"
             }, {
                 "id": 755735878,
                 "name": "50,000 MB for 20 GEL",
                 "productType": "package",
                 "productId": 755735878,
                 "is_purchasable": true,
                 "photo": "wof-pics/p/en/755735878.png"
             }]
         }, "timestamp": null
     };*/

    return await Repository.get(`${baseUrl}/wof/status`, {
        headers: {
            'token': `${token}`,
            'channel': 'WOF'
        }
    })
        .then(result => {
            if (result.data) {

                if (result.data.code === 200) {
                    result.data.data.prizeList = sortWOFSlices(result.data.data.prizeList);
                    return result.data;
                } else {
                    return result.data;
                }
            }
            return {code: 1001, message: 'Network error'}
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function spinWOF(token) {

    /*return {
        "code": 200,
        "message": "OK",
        "data": {
            "prize":  {
                "id": 755735877,
                "name": "1,000 minutes for 12 GEL",
                "productType": "package",
                "productId": 755735877,
                "is_purchasable": true,
                "photo": "wof-pics/p/en/755735877.png"
            }
        },
        "token": token
    };*/
    return await Repository.post(`${baseUrl}/wof/spin`, null, {
        headers: {
            'token': `${token}`,
            'channel': 'WOF'
        }
    })
        .then(result => {
            if (result.data) {
                return result.data;
            }
            return {code: 1001, message: 'Network error'}
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}


//------------- MEMBERS --------------

export async function userEmailRequest(email, token) {
    return await Repository.post(`${baseUrl}/user/email/request`,
        {
            "email": `${email}`
        },
        {
            headers: {
                'token': `${token}`,
                'channel': 'WOF'
            }
        })
        .then(result => {
            console.log('userEmailRequest', result)
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}


export async function userEmailVerify(email, otp, token) {
    return await Repository.post(`${baseUrl}/user/email/verify`,
        {
            "email": `${email}`,
            "otp": `${otp}`
        },
        {
            headers: {
                'token': `${token}`,
                'channel': 'WOF'
            }
        })
        .then(result => {
            console.log('userEmailVerify', result)
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}


//------------- MEMBERS --------------

export async function userMemberRequest(val1, val2) {
    return await Repository.post(`${baseUrl}/user/member/request`,
        {
            "msisdn": `${val1}`
        },
        {
            headers: {
                'token': `${val2}`,
                'channel': 'WOF'
            }
        })
        .then(result => {
            console.log('userMemberRequest', result)
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}

export async function userMemberVerify(val1, val2, val3) {
    return await Repository.post(`${baseUrl}/user/member/verify`,
        {
            "msisdn": `${val1}`,
            "otp": `${val2}`
        },
        {
            headers: {
                'token': `${val3}`,
                'channel': 'WOF'
            }
        })
        .then(result => {
            console.log('userMemberVerify', result)
            return result.data
        })
        .catch(error => {
            console.log('error', error);
            return {code: 1000, message: 'Network error'}
        });
}


function sortWOFSlices(list) {

    let countMatrix  = {};

    list.forEach(e=>{
        if(countMatrix[e.colourCode]){
            countMatrix[e.colourCode] +=1;
        } else {
            countMatrix[e.colourCode] = 1;
        }
    })

    list = list.sort((a,b)=>{
        return countMatrix[b.colourCode] -  countMatrix[a.colourCode] ;
    })

    let finalList = [];
    let lastColor = -1;
    while (list.length > 0) {

        let item = getNextSlice(lastColor, list);
        if (item !== null) {
            finalList.push(item);
            lastColor = item.colourCode;
            list = list.filter(e => e.id !== item.id);
        } else {
            finalList = [...finalList, ...list];
            list = [];
        }
    }

    return finalList;
}

function getNextSlice(lastColor, currentList) {
    let item = null;
    currentList.forEach(e => {
        if (e.colourCode !== lastColor && item === null) {
            item = e;
            console.log('new:' + e.colourCode);
        }
    })

    return item;
}