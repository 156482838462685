<template>
  <div class=" main-bg" >
    <div class="container" style="">
      <div
        class="mColor bgp text-center"
        style="border-radius: 20px; padding: 25px; height: 75vh"
      >
        <div class="col pb-3">
          <h4
            class="pt-5 pb-3"
            style="font-weight: bold; color: black; font-size: 24px"
          >
            {{ $t("homePage.homeWheel") }}
          </h4>
          <p style="font-size: 18px; color: black" class="mb-4">{{ $t("homePage.homeWheelP") }}</p>
          <p>
          <router-link :to="{ name: 'Wheel' }" class=" mt-3 eButton"   >
            {{ $t("homePage.homeWheelB") }}
          </router-link>
          </p>
        </div>
        <div class="col pb-2">
          <img style="height: 40vh" src="@/assets/wheel.png" alt="" />
        </div>
      </div>
    </div>

    <!-- <b-modal
      size="md"
      ref="ml-modal"
      centered
      id="ml-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div style="">
        <img src="@/assets/email.svg" style="margin-top: 20px" alt="" />
      </div>
      <div class="d-block">
        <h4 class="mColor" style="font-weight: bold; margin-top: 50px">
          {{ $t("homePage.addEmail") }}
        </h4>
        <p style="padding-top: 30px; color: #5f6b81">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum placeat
          sint obcaecati? Maxime, illo dolorum
        </p>
        <button
          style="margin-bottom: 20px"
          class="mButton col"
          @click.prevent="$router.push({ name: 'AddEmail' })"
        >
          {{ $t("loginPage.settings") }}
        </button>
        <br />
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomePage",
  data() {
    return {
      productListLoading: true,
      packageList: [],
      bonusList: [],
    };
  },

   mounted() {
    this.userProfile(localStorage.getItem("token"));
  },
  watch: {
    userMe(newVal, oldVal) {
      if (newVal && newVal.user ) {
        newVal.user.language.code
            ? (this.$i18n.locale = this.userMe.user.language.code)
            : (this.$i18n.locale = "en");
        if (!oldVal && newVal) {
          if (!newVal.user.email) {
            //this.$refs["ml-modal"].show();
          }
        }
      }
    },
  },
  methods: {
    ...mapActions(["userProfile"]),
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["userMe"]),
  },
};
</script>

<style scoped>

.container{
  max-width: 500px;
}

.eButton {
  background: #d26dab;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  font-size: 20px;
}
</style>
