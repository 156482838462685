import {createRouter, createWebHistory} from 'vue-router'
import LandingPage from '../views/Login/LandingPage.vue'
//import HomePage from '../views/Home/HomePage.vue'

import validate from '../store/index'
import NewWheel from "@/views/Home/NewWheel";
import Privacy from "@/views/Profile/Privacy";
import Terms from "@/views/Profile/Terms";
import Faq from "@/views/Profile/Faq";
import Language from "@/views/Profile/Language";
import Details from "@/views/Profile/Details";
import Profile from "@/views/Profile/Profile";
import Verify from "@/views/Login/Verify";
import LoginInd from "@/views/Login/LoginInd";
import Identify from "@/views/Login/Identify";
import HomePage from "@/views/Home/HomePage";


const routes = [
  {
    path: '/',
    name: LandingPage,
    component: LandingPage,
    beforeEnter: (to, from, next) => {
      if (validate.state.userMe) {
        next('/HomePage');
      } else {
        next();
      }
    },
  },
  {
    path: '/LoginInd',
    name: 'LoginInd',
    component: LoginInd,
    beforeEnter: (to, from, next) => {
      if (validate.state.userMe) {
        next('/HomePage');
      } else {
        next();
      }
    },
  },
  {
    path: '/Verify',
    name: 'Verify',
    component: Verify,
    beforeEnter: (to, from, next) => {
      if (validate.state.userMe) {
        next('/HomePage');
      } else {
        next();
      }
    },
  },
  {
    path: '/identify',
    name: 'Identify',
    component: Identify,
    beforeEnter: (to, from, next) => {
      if (validate.state.userMe) {
        next('/HomePage');
      } else {
        next();
      }
    },
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
    ,
  },
  {
    path: '/Details',
    name: 'Details',
    component: Details,
  },
  {
    path: '/Language',
    name: 'Language',
    component: Language,
  },
  {
    path: '/Faq',
    name: 'Faq',
    component: Faq

  },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/Wheel',
    name: 'Wheel',
    component: NewWheel,
  },
  {
    path: '/HomePage',
    name: "HomePage",
    component: HomePage,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
