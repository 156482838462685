<template>
  <div class="main-bg">
  <div class="container" style="height: 100vh">
    <button
      @click="$router.go(-1)"
      style="
        background: none;
        border: none;
        margin: 0;
        padding-top: 50px;
        color: white;
      "
    >
      <img style="margin-right: 10px" src="@/assets/back.svg" alt="" />
      <span v-if="terms">{{ terms.title }}</span>
    </button>
    <p
      class="sColor"
      style="margin-top: 35px; margin-bottom: 55px; font-size: 16px" v-if="terms" v-html="terms.text"
    ></p>
    <!-- <b-button class="mButton col" style="margin-top: 20px">
      {{ $t("accept") }}
    </b-button> -->
  </div>
  </div>
</template>

<script>
import { generalPage } from "../../repository/DataRepository";

export default {
  name: "TermsPage",
  data() {
    return {
      terms: null,
    };
  },
  async mounted() {
    this.getPage();
  },

  methods: {
    getPage() {
      generalPage( this.$i18n.locale, 'terms').then((res) => {
        this.terms = res.data.data.page[0];
      });
    },
  },

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.pp {
  text-align: center;
  margin-top: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  font-size: 14px;
  border-top: 1px solid rgba(236, 236, 236, 0.1);
}
</style>
