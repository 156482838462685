<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col px-5 position-relative"
        style="padding-top: 15vh; padding-bottom: 6vh; min-height: 100vh; text-align: center"
      >

        <div class="d-flex justify-content-between"
             style="position: absolute; top: 50px; left: 50px; right: 50px;">
          <button
              @click="$router.go(-1)"
              style="background: none; border: none; padding: 0; margin-right: 10px"
          >
            <img
                src="@/assets/back.svg"
                alt=""
            />
          </button>

          <a href="#" class="text-white" @click.prevent="changeLanguage('en')" v-if="$i18n.locale==='ka'">English</a>
          <a href="#" class="text-white" @click.prevent="changeLanguage('ka')" v-else>ქართული</a>
        </div>
        <br />
        <h1 style="font-size: 28px; font-weight: bold" class="mColor">
          {{ $t("loginPage.verifyCheck") }}
        </h1>
        <p
          class="sColor"
          style="margin-top: 35px; margin-bottom: 55px; font-size: 16px"
        >
          {{ $t("loginPage.verifyP") }} <br />
          <span style="color: #d26dab">+995{{ number }}</span>
        </p>
        <div class="row" style="margin-bottom: 20px">
          <input
            class="mr-2 ml-2 mb-4 input-item"
            v-model="OTP"
            :placeholder="$t('loginPage.enterVerificationCode')"
          />
        </div>
        <button @click="verify(transaction, OTP)" class="mButton col">
          {{ $t("loginPage.send") }}
        </button>
        <br />
        <p
          class="mColor"
          style="text-align: center; padding-top: 150px; font-size: 14px"
        >
          {{ $t("loginPage.verifyResend") }}
        </p>
      </div>

      <div
        class="
          d-none d-sm-block d-md-none d-lg-block
          col-md-8 col-lg-8 col-xl-8
          rside
        "
      ></div>
    </div>
  </div>
  <div class="modal" ref="verifyModal" id="verifyModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-body"
          style="
            color: white;
            background: #071d42;
            padding: 50px;
            font-weight: bold;
          "
        >
          <p>{{ statusResponse }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { userVerify } from "@/repository/DataRepository";

export default {
  name: "VerifyInd",
  data() {
    return {
      OTP: "",
      token: "example",
      number: "",
      transaction: "",
      statusResponse: "",
    };
  },
  async created() {
    this.number = this.$route.params.number;
    this.transaction = this.$route.params.transaction;
    if(!this.number || !this.transaction ){
      await this.$router.push({name: "LoginInd"});
    }
  },

  methods: {
    verify(val, val2) {
      userVerify(val, val2).then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("token", res.data.token);
          this.$router.push({ path: "/HomePage" });
          setTimeout(() => {
            window.location.reload();
          }, "500");
        } else {
          this.statusResponse = res.data.message;
          let myModal = new Modal(document.getElementById("verifyModal"));
          myModal.toggle();
        }
      });
    },

    changeLanguage(code){
      this.$i18n.locale = code;
      localStorage.setItem("locale", code);
    }
  },

  components: {},
  props: {},

  computed: {},
};
</script>

