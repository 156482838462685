<template>
  <nav
      class="navbar navbar-dark"
      style="
      background: #00234e;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    "
  >
    <div class="container">
      <router-link class="navbar-brand" :to="{name: 'HomePage'}">
        <img
            src="@/assets/Silknet.svg"
            alt=""
            width="130"
            height="40"
            class="d-inline-block align-text-top"
        />
      </router-link>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item" style="font-weight: bold">
<!--          <router-link
              v-if="name"
              style="text-decoration: none"
              :to="{ name: 'Profile' }"
          >
            <a class="nav-link d-flex align-items-center">
              <div
                  v-if="userMe.user.photo"
                  class="rounded-circle d-inline-block me-2"
                  style="
                  width: 38px;
                  height: 38px;
                  border: 1px solid rgba(255, 255, 255, 0.3);
                  background-size: cover;
                "
                  :style="{'background-image':`url(${baseUrl}/${userMe.user.photo})`}"
              />
              <img
                  v-else
                  style="
                  width: 35px;
                  background: rgba(255, 255, 255, 0.8);
                  padding: 3px;
                  border-radius: 50%;
                  border: 2px solid rgba(255, 255, 255, 0.3);
                  margin-right: 15px;
                "
                  src="@/assets/noImage.svg"
                  alt=""
              />
              <div class="d-inline-block">{{ name }}</div>
            </a>
          </router-link>-->
          <div v-if="name || phone" class=" text-primary" role="status" @click.prevent="logoutUser">
             <img src="../assets/logout.svg">
          </div>
          <div v-else class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link"
            >{{ name }}
            {{ phone[0].phone }}
          </a>
        </li> -->
      </ul>
    </div>
  </nav>
  <!-- <div
    class="container-fluid"
    style="border-bottom: 1px solid rgba(255, 255, 255, 0.2); height: 7vh"
  >
    <div class="container">
      <div class="row">
        <div class="col" style="line-height: 7vh">
          <router-link :to="{ path: '/HomePage' }">
            <img style="width: 130px" src="@/assets/Silknet.svg" alt="" />
          </router-link>
        </div>
        <span v-if="phone" class="col" style="float: right">
          <img
            style="
              width: 35px;
              background: rgba(255, 255, 255, 0.8);
              padding: 5px;
              border-radius: 50%;
              border: 1px solid rgba(255, 255, 255, 0.5);
              margin-right: 15px;
            "
            src="@/assets/noImage.svg"
            alt=""
          />
          {{ name }} <br />
          {{ phone[0].phone }}
        </span>
      </div>
    </div>
  </div> -->
</template>

<script>
import {baseUrl} from "@/repository/repository";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      baseUrl: baseUrl,
      color: "#071d42",
      phone: "",
      name: "",
    };
  },
   created() {
    this.userProfile(localStorage.getItem("token"));
    this.processUserData(this.userMe);
  },
  watch: {
    userMe: {
      deep: true,
      handler(newVal) {
        this.processUserData(newVal);
      }
    },
  },
  methods: {
    ...mapActions(["userProfile"]),
    processUserData(newVal) {
      console.log("newVal");
      console.log(newVal);
      if (newVal) {
        if (newVal.user) {
          this.name = newVal.user.name;
          this.phone = newVal.members.filter((num) => num.isMain === "true");
          this.$i18n.locale = newVal.user.language.code;
          localStorage.setItem("locale",  newVal.user.language.code);
        }
      }
    },
    logoutUser() {
      if(confirm(this.$t('logoutQuestion'))) {
        localStorage.removeItem("token");
        document.location.href = "/";
      }
    },
  },

  computed: {
    ...mapGetters(["userMe"]),
  },
};
</script>

<style scoped>
</style>
