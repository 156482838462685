<template>
  <div class="main">
    <NavBar v-if="token" />
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  data() {
    return {
      token: false,
      color: "#032046",
    };
  },
  created() {


    let locale = localStorage.getItem("locale");

    if(['en','ka'].indexOf(locale)>-1){
      this.$i18n.locale = locale;
    } else {
      this.$i18n.locale = 'ka';
      localStorage.setItem('locale', 'ka');
    }

    setInterval(
        ()=>{
          if (localStorage.getItem("token")) {
            this.token = true;
            //this.color = "linear-gradient(#002046, #D26DAB)";
          }
        }, 1000
    )

  },
  components: {
    NavBar,
  },
};
</script>

<style>
* {
  margin: 0;
}
.main {
  background: v-bind(color);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  position: relative;
  color: white;
}

.mButton {
  background: #d26dab;
  border: none;
  border-radius: 12px;
  padding: 15px 120px 15px 120px;
  font-weight: 550;
  color: white;
  text-decoration: none;

}

.input-item{
  color: white;
  background: none;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid rgba(236, 236, 236, 0.1);
}

.input-item:focus, .input-item:focus-visible{
  outline: none;
}

.main-bg{
  padding-bottom: 80px; min-height: 100vh;
  background-image: url('assets/wheel_background.png');
  background-size: cover;
  background-clip: padding-box;
  background-position: center;
}

.rside {background-image: url("assets/bg_circle.jpg");
  background-size: cover;
  height: 100vh;
}

</style>
