export default {
  "landingPage": {
    "welcomeH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
    "welcomeSlogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are here, therefore you are the loyal subscriber of the Silknet network.<br>Thank you for your loyalty and here is your chance to win the gift.\n"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your gift"])},
    "corpAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Account"])},
    "welcomeFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By registering or logging in, you are agreeing with our "])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Notice"])},
    "privacyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://silknet.com/en/company/terms-and-conditions/content-category/102079"])}
  },
  "loginPage": {
    "loginIndH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start as Individual account"])},
    "loginCorpH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start as Corporate account"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any problem with your login details? Get help!"])},
    "verifyCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your messages!"])},
    "verifyP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete the registration, you need to enter the OTP code sent to"])},
    "verifyResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn’t receive any message? Resend SMS"])},
    "enterMobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter mobile number"])},
    "enterVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter verification code"])}
  },
  "navBar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "homePage": {
    "homeWheel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spin the wheel and win a gift"])},
    "homeWheelP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not miss the chance to win different prizes every week! spin the wheel and win one of the prizes from the wheel"])},
    "homeWheelB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])},
    "homeBL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus List"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got the prize!!"])},
    "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add email to account"])},
    "noBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active bonus"])},
    "noPack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active package"])},
    "homePL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package List"])},
    "homePurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which member do you want to buy this product for?"])},
    "homePurchaseBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
    "homePurchaseCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Email for confirmation"])}
  },
  "balancePage": {
    "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])},
    "spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spendings"])},
    "noRec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No record on this section"])},
    "gCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is a G coin?"])}
  },
  "profilePage": {
    "loyaltyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty Group"])},
    "addMem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new member"])},
    "fls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed line services"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal ID"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Now"])},
    "pd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Details"])},
    "lan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "removeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove number from Members?"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
  },
  "detailsPage": {
    "cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tap to change your profile picture"])},
    "fn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "bd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "enableIdPage": {
    "efs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Fixed Services Loyalty"])},
    "tryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try another ID"])},
    "selectNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select contact number for loyalty account"])}
  },
  "addEmailPage": {
    "eUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail updated"])},
    "goProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to profile"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continue"])},
    "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update my email"])}
  },
  "addMemberPage": {
    "moveGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to my group"])},
    "newMem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New member added"])}
  },
  "wof": {
    "winPrize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your prize"])},
    "spin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spin to win"])},
    "alreadySpinned": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already tried your luck! "])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait to the next round, which we will let you know by SMS!"])}
    },
    "success": {
      "package": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congrats!"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have won and will be able to activate ", _interpolate(_named("name")), " within 30 days via *212#"])}
      },
      "bonus": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Congrats! You have won package ", _interpolate(_named("name")), "."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package will be activated by receving SMS confirmation. Status check *212*1#"])}
      }
    },
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialization..."])}
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "logoutQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to logout?"])}
}