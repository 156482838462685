export default {
  "landingPage": {
    "welcomeH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კეთილი იყოს შენი მობრძანება!"])},
    "welcomeSlogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["თუ შენ ამ ბმულზე აღმოჩნდი, ესე იგი სილქნეტის ქსელის ერთგული მომხმარებელი ხარ.<br>მადლობის ნიშნად კი გთავაზობთ საჩუქრის მოგების შანს!"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მიიღე საჩუქარი"])},
    "corpAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კორპორატიული ანგარიში"])},
    "welcomeFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რეგისტრაციით ან ავტორიზაციით თქვენ ეთანხმებით ჩვენს "])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პირობებს"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["კოფიდენციალურობის განაცხადი"])},
    "privacyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://silknet.com/ge/company/terms-and-conditions/content-category/102079"])}
  },
  "loginPage": {
    "loginIndH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შექმენით პერსონალური ანგარიში"])},
    "loginCorpH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შექმენით კორპორატიული ანგარიში"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შემდეგი"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გაგზავნა"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პარამეტრები"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შეგექმნათ პრობლემა ვერიფიკაციისას? მიიღეთ დახმარება!"])},
    "verifyCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შეამოწმეთ თვენი წერილები!"])},
    "verifyP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რეგისტრაციის დასასრულებლად, შეიყვანეთ გამოგზავნილი ერთჯერადი კოდი"])},
    "verifyResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["არ მიგიღიათ წერილი? ხელახლა გაგზავნა"])},
    "enterMobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შეიყვანეთ მობილურის ნომერი"])},
    "enterVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შეიყვანეთ დამადასტურებელი კოდი"])}
  },
  "navBar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მთავარი"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბალანსი"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პროფილი"])}
  },
  "homePage": {
    "homeWheel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბორბალი"])},
    "homeWheelP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მოიგე სხვადასხვა პრიზი ყოველ კვირას"])},
    "homeWheelB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ითამაშეთ"])},
    "homeBL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ბონუსების სია"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იტვირთება"])},
    "prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["თქვენ დასაჩუქრდით!!"])},
    "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დაამატეთ ელ-ფოსტა ანგარიშზე"])},
    "noBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["აქტიური ბონუსი არ მოიძებნა"])},
    "noPack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["აქტიური პაკეტი არ მოიძებნა"])},
    "homePL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პაკეტების სია"])},
    "homePurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რომელი წევრისთვის გსურთ ამ პროდუქტის შეძენა?"])},
    "homePurchaseBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["იყიდეთ ახლა"])},
    "homePurchaseCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩაწერეთ თქვენი ელფოსტა დასადასტურებლად"])}
  },
  "balancePage": {
    "earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შემოსავალი"])},
    "spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ხარჯი"])},
    "noRec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ამ განყოფილებაში ჩანაწერი არ არის"])},
    "gCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["რა არის ჯი მონეტა?"])}
  },
  "profilePage": {
    "loyaltyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ლოიალობის ჯგუფი"])},
    "addMem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დაამატეთ ახალი წევრი"])},
    "fls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ფიქსირებული მომსახურება"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პერსონალური აიდი"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გააქტიურება"])},
    "pd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პროფილის დეტალები"])},
    "lan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ენა"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ხშირად დასმული კითხვები"])},
    "tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["წესები და პირობები"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გამოსვლა"])},
    "removeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გსურთ ნომრის წაშლა?"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["წაშლა"])}
  },
  "detailsPage": {
    "cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პროფილის სურათის შესაცვლელად დააწკაპუნეთ"])},
    "fn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სახელი და გვარი"])},
    "bd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დაბადების თარიღი"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ელ-ფოსტა"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სქესი"])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ატვირთვა"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["მობილური"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["განახლება"])}
  },
  "enableIdPage": {
    "efs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩართეთ ფიქსირებული სერვისების ლოიალობა"])},
    "tryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["სცადეთ სხვა აიდი"])},
    "selectNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გთხოვთ, აირჩიოთ საკონტაქტო ნომერი ლოიალობის ანგარიშისთვის"])}
  },
  "addEmailPage": {
    "eUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ელ-ფოსტა განახლებულია"])},
    "goProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გადადით პროფილზე"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გაგრძელება"])},
    "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩემი ელ-ფოსტის განახლება"])}
  },
  "addMemberPage": {
    "moveGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ჩემს ჯგუფში გადასვლა"])},
    "newMem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ახალი წევრი დამატებულია"])}
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დათანხმება"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დადასტურება"])},
  "wof": {
    "winPrize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your prize"])},
    "spin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დაატრიალეთ მოსაგებად"])},
    "alreadySpinned": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["შენ უკვე გამოსცადე იღბალი!  "])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დაელოდე მომდევნო გათამაშებას, რომლის შესახებ SMS-ის სახით შეგატყობინებთ!"])}
    },
    "success": {
      "package": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["გილოცავ!"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["შენ მოიგე და შეგიძლია გაიაქტიურო ", _interpolate(_named("name")), " 30 დღის განმავლობაში *212# აკრეფით."])}
      },
      "bonus": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["გილოცავ! შენ მოიგე პაკეტი ", _interpolate(_named("name")), "."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["პაკეტი გაგიაქტიურდება დამადასტურებელი SMS შეტყობინების მიღებისთანავე. სტატუსის შემოწმება *212*1#"])}
      }
    },
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ინიციალიზაცია..."])}
  },
  "logoutQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["დარწმუნებული ხართ, რომ გსურთ გამოსვლა?"])}
}