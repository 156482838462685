<template>
  <div class="container-fluid">
    <div class="row">
      <div
          class="col px-5 position-relative"
          style="padding-top: 15vh; padding-bottom: 4vh; min-height: 100vh; text-align: center"
      >

        <div class="d-flex justify-content-between"
             style="position: absolute; top: 50px; left: 50px; right: 50px;">
        <button
            @click="$router.go(-1)"
            style="background: none; border: none; padding: 0; margin-right: 10px"
        >
          <img
              src="@/assets/back.svg"
              alt=""
          />
        </button>

            <a href="#" class="text-white" @click.prevent="changeLanguage('en')" v-if="$i18n.locale==='ka'">English</a>
            <a href="#" class="text-white" @click.prevent="changeLanguage('ka')" v-else>ქართული</a>
        </div>
        <img style="margin-bottom: 50px" src="@/assets/Silknet.svg" alt=""/>
        <h1 style="font-size: 28px; font-weight: bold" class="mColor">
          {{ $t("loginPage.loginIndH") }}
        </h1>

        <p style="margin-bottom: 55px; font-size: 16px; color: #c6cedd"
           v-html="`${$t('landingPage.welcomeSlogan')}`"></p>

        <form action="" @submit.prevent="checkStatus">
          <div class="d-flex" style="margin-bottom: 40px">
            <img
                style="margin-bottom: 3px"
                src="@/assets/mobile.svg"
                alt=""/>
            <input required
                   class="w-100 ms-3 input-item"
                   v-model="number"
                   :placeholder="$t('loginPage.enterMobileNumber')"
            />
          </div>
          <button class="mButton" type="submit">
            {{ $t("loginPage.next") }}
          </button>
        </form>
        <br/>
        <p
            class="mColor"
            style="
            text-align: center;
            padding-top: 35px;
            padding-bottom: 35px;
            font-size: 14px;
          "
        >
          {{ $t("loginPage.help") }}
        </p>
      </div>

      <div
          class="
          d-none d-sm-block d-md-none d-lg-block
          col-md-8 col-lg-8 col-xl-8
          rside
        "
      ></div>
    </div>
  </div>
  <div class="modal" ref="loginModal" id="loginModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
            class="modal-body"
            style="
            color: white;
            background: #071d42;
            padding: 50px;
            font-weight: bold;
          "
        >
          <p>{{ statusResponse }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {userStatus, userOtp} from "@/repository/DataRepository";

export default {
  name: "LoginInd",
  data() {
    return {
      number: "",
      statusResponse: "",
      statusCode: "",
    };
  },
  mounted() {
  },

  methods: {
    checkStatus() {

      let number = this.number.slice(this.number.length - 9);

      userStatus(number).then((res) => {
        this.statusResponse = res.message;
        this.statusCode = res.code;

        // only 200 below

        if (res.code === 200 || res.code === 404) {
          this.otp(number);
        } else {
          let myModal = new Modal(document.getElementById("loginModal"));
          myModal.toggle();
        }
      });
    },
    otp(formattedMsisdn) {
      userOtp(formattedMsisdn).then((res) => {
        if (res.data.code === 200) {
          this.$router.push({
            name: "Verify",
            params: {
              number: formattedMsisdn,
              transaction: res.data.data.transaction,
            },
          });
        } else {
          let myModal = new Modal(document.getElementById("loginModal"));
          myModal.toggle();
        }
      });
    },

  changeLanguage(code){
    this.$i18n.locale = code;
    localStorage.setItem("locale", code);
  }
  },

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
</style>
