<template>
  <div class="main-bg">
  <div class="container" style="padding-bottom: 90px">
    <button
      @click="$router.go(-1)"
      style="background: none; border: none; padding: 0; margin: 0"
    >
      <img
        style="padding-top: 50px; margin-bottom: 55px"
        src="@/assets/back.svg"
        alt=""
      />
    </button>
    <h1
      style="font-size: 28px; font-weight: bold; text-align: center"
      class="mColor"
    >
      {{ $t("profilePage.pd") }}
    </h1>
    <p
      class="sColor"
      style="
        margin-top: 35px;
        margin-bottom: 55px;
        font-size: 16px;
        text-align: center;
      "
    >
      You can change you personal information from here. All changes will be
      saved and updated in interface
    </p>
    <div
      class="container"
      style="padding-bottom: 20px; margin-top: 20px; text-align: center"
    >
      <div class="row">
        <div class="" v-if="userMe" @click.prevent="selectPhoto">
          <img
            :src="`${baseUrl}/${userMe.user.photo}`"
            style="
              width: 80px;
              height: 80px;
              border-radius: 45%;
              border: #002976 3px solid;
            "
            alt=""
          />
        </div>
        <!-- <div
          class="col-4"
          v-else-if="userMe.accountType === 'Company'"
          @click.prevent="selectPhoto"
        >
          <img
            :src="`${baseUrl}/${userMe.company.photo}`"
            style="
              width: 80px;
              height: 80px;
              border-radius: 45%;
              border: #002976 3px solid;
            "
            alt=""
          />
        </div> -->
        <div class="col sColor">
          <p style="margin-top: 15px" v-if="uploadProgress > 0">
            {{ $t("uploading") }} {{ uploadProgress }}%
          </p>
          <p style="margin-top: 15px" v-else>{{ $t("detailsPage.cp") }}</p>
        </div>
      </div>
      <div style="color: white" class="row mt-4">
        <input
          v-if="uploadProgress < 1"
          type="file"
          style="display: none"
          id="fileInput"
          ref="file"
          @change="photoSelected"
        />
      </div>
    </div>
    <div
      class="mColor pl-3"
      v-if="userMe.accountType === 'Private Person'"
      style="text-align: center"
    >
      <p
        class=""
        style="
          padding-top: 30px;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
        "
      >
        {{ $t("detailsPage.fn") }}
      </p>
      <div class="row mx-3 justify-content-center" style="margin-bottom: 20px">
        <div
          class="col-lg-5 col-xl-5 mb-1"
          style="
            background: rgba(198, 206, 221, 0.2);
            border: none;
            color: white;
            padding: 12px;
            border-radius: 10px;
            text-align: left;
          "
        >
          <input
            style="
              color: white;
              background: none;
              border-radius: 0px;
              border: none;
            "
            v-model="name"
            placeholder="your name"
          /><img style="float: right" src="@/assets/pen.svg" alt="" />
        </div>
      </div>

      <p
        class=""
        style="
          padding-top: 30px;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
        "
      >
        {{ $t("detailsPage.bd") }}
      </p>
      <div class="row mx-3 justify-content-center" style="margin-bottom: 20px">
        <div
          class="col-lg-5 col-xl-5 mb-1"
          style="
            background: rgba(198, 206, 221, 0.2);
            border: none;
            color: white;
            padding: 12px;
            border-radius: 10px;
          "
        >
          <span class="">
            <input
              v-model="date"
              class="form-control"
              style="background: none; border: none; color: white; width: 300px"
              :placeholder="date"
              :type="type"
              onfocus="(this.type='date')"
              id="date"
            />
          </span>
        </div>
      </div>

      <!-- <p class="" style="margin-top: 30px; color: #00a5ff; font-size: 14px">
        {{ $t("detailsPage.bd") }}
      </p>
      <div class="row" style="margin-bottom: 20px">
        <input
          v-if="userMe"
          disabled
          class="col-9 ml-3"
          style="
            color: white;
            background: none;
            border-radius: 0px;
            border-bottom: 1px solid rgba(236, 236, 236, 0.1);
            border-top: none;
            border-left: none;
            border-right: none;
          "
          v-model="date"
          placeholder="your birthdate"
        />
        <span class="col-3">
          <input
            id="startDate"
            v-model="date"
            class="form-control"
            style="background: none; border: none; color: white"
            type="date"
          />
        </span>
      </div> -->

      <p
        class=""
        style="
          margin-top: 30px;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
        "
      >
        {{ $t("detailsPage.gender") }}
      </p>
      <div class="row mx-3 justify-content-center">
        <div class="form-check col-lg-1 col-xl-1 col-md-2">
          <input
            v-model="gender"
            v-bind:true-value="'male'"
            class="form-check-input"
            type="checkbox"
            value="male"
            id="flexCheckDefault"
            :checked="gender === 'male'"
          />
          <label class="form-check-label" for="flexCheckDefault"> Male </label>
        </div>

        <div class="form-check col-lg-1 col-xl-1 col-md-2">
          <input
            v-model="gender"
            v-bind:true-value="'female'"
            class="form-check-input"
            type="checkbox"
            value="female"
            id="flexCheckDefault2"
            :checked="gender === 'female'"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Female
          </label>
        </div>
      </div>

      <button
        class="mButton col"
        @click="updateProfile()"
        style="margin-top: 20px; text-align: center"
      >
        {{ $t("detailsPage.update") }}
      </button>
    </div>
    <div class="mColor pl-3" style="text-align: center">
      <p class="" style="margin-top: 30px; color: #ffffff; font-size: 14px">
        {{ $t("detailsPage.mobile") }}
      </p>
      <p class="" style="font-size: 18px" v-if="userMe">
        +995 {{ userMe.members[0].phone }}
      </p>

      <p class="" style="margin-top: 30px; color: #ffffff; font-size: 14px">
        {{ $t("detailsPage.mail") }}
      </p>
      <p class="" style="font-size: 18px">
        {{ userMe.user.email || "-" }}
        <!-- <router-link :to="{ name: 'AddEmail' }" class="btn btn-sm btn-primary"
          >edit</router-link -->
      </p>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { userProfileUpdate, userImage } from "../../repository/DataRepository";
import { baseUrl } from "../../repository/repository";

export default {
  name: "DetailsPage",
  data() {
    return {
      baseUrl: baseUrl,
      name: "",
      date: "",
      email: "",
      gender: "",
      token: "",
      uploadProgress: 0,
    };
  },
  async mounted() {
    this.token = localStorage.getItem("token");
    this.userProfile(localStorage.getItem("token"));
  },

  methods: {
    ...mapActions(["userProfile"]),

    updateProfile() {
      userProfileUpdate(this.name, this.date, this.gender, this.token).then(
        () => {
          this.userProfile(localStorage.getItem("token"));
        }
      );
    },

    selectPhoto() {
      window.document.getElementById("fileInput").click();
    },

    photoSelected() {
      let currentFile = this.$refs.file.files[0];

      userImage(this.token, currentFile, (event) => {
        this.uploadProgress = Math.round((100 * event.loaded) / event.total);
      }).then(() => {
        this.uploadProgress = 0;
        this.userProfile(localStorage.getItem("token"));
      });
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["userMe"]),
  },
  watch: {
    userMe() {
      if (this.userMe.user) {
        this.gender = this.userMe.user.gender;
        this.name = this.userMe.user.name;
        this.date = this.userMe.user.birthday;
      } else if (this.userMe.company) {
        this.name = this.userMe.company.name;
      }
    },
  },
};
</script>


<style scoped>
</style>
<style>
.btn-light {
  color: #637792;
  background-color: #002046;
  border-color: #002046;
}
</style>
