<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col" style="padding-top: 5vh; padding-bottom: 11vh; min-height: 100vh; text-align: center" >

        <div class="d-flex justify-content-end" style="margin-bottom: 10vh">
          <a href="#" class="text-white" @click.prevent="changeLanguage('en')" v-if="$i18n.locale==='ka'">English</a>
          <a href="#" class="text-white" @click.prevent="changeLanguage('ka')" v-else>ქართული</a>
        </div>

        <img style="margin-bottom: 60px" src="@/assets/Silknet.svg" alt="" />
        <h1 style="font-size: 28px; font-weight: bold" class="mb-5">
          {{ $t("landingPage.welcomeH") }}
        </h1>
        <p style="margin-bottom: 55px; font-size: 16px; color: #c6cedd"
           v-html="`${$t('landingPage.welcomeSlogan')}`"></p>
          <button  @click.prevent="startLogin" class="mButton mb-3">
            {{ $t("landingPage.startNow") }}
          </button>
        <br />
        <p class="pp">
          {{ $t("landingPage.welcomeFooter") }}
          <router-link
            :to="{ name: 'Terms' }"
            style="
              color: #d26dab;
              font-size: 14px;
            "
          >
            {{ $t("landingPage.terms") }}
          </router-link>
          <br>
          <a
            :href="$t('landingPage.privacyLink')" target="_blank"
            style="
              color: #d26dab;
              font-size: 14px;
            "
          >
            {{ $t("landingPage.privacy") }}
          </a>
        </p>
      </div>
      <div
        class="
          d-none d-sm-block d-md-none d-lg-block
          col-md-8 col-lg-8 col-xl-8
          rside
        "
      ></div>
    </div>
  </div>
</template>

<script>
import {generalSettings, heStart, userMe} from "@/repository/DataRepository";

export default {
  name: "LandingPage",
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    let token = localStorage.getItem("token");
    if (token) {
      userMe(token).then((response) => {
        if (response.data) {
          if (response.data.code === 200) {
            this.$router.push({ path: "/HomePage" });
          } else {
            localStorage.removeItem("token");
          }
        }
      });
    }
    this.getSettings();
  },

  methods: {
    getSettings() {
      generalSettings().then((res) => {
        this.data = res;
      });
    },
    startLogin() {
      heStart().then(data=>{
        if(data.code === 200){
          //redirection
          location.href = `http://idforyou.silknet.com/notify/trx/${data.data.trxId}/detect`;
        } else {
          this.$router.push({name: "LoginInd"})
        }
      })
    },
    changeLanguage(code){
      this.$i18n.locale = code;
      localStorage.setItem("locale", code);
    }
  },

  components: {},
  props: {},

  computed: {
    // ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.pp {
  text-align: center;
  margin-top: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  font-size: 14px;
  border-top: 1px solid rgba(236, 236, 236, 0.1);
}
</style>
