<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col px-5"
        style="padding-top: 15vh; padding-bottom: 6vh; min-height: 100vh; text-align: center"
      >
         Loading...
      </div>

      <div
        class="
          d-none d-sm-block d-md-none d-lg-block
          col-md-8 col-lg-8 col-xl-8
          rside
        "
      ></div>
    </div>
  </div>

</template>

<script>

import {heEnd} from "@/repository/DataRepository";

export default {
  name: "IdentifyPage",
  data() {
    return {
    };
  },
  mounted() {

    let trx = this.$route.query['trx'];
    if(trx){
      heEnd(trx).then(data=>{
        console.log(data);
        if(data.code===200){
          localStorage.setItem("token", data.data.token);
          this.$router.replace({ name: "HomePage" });
        } else {
          this.$router.replace({'name': "LoginInd"});
        }
      })
    } else {
      this.$router.replace({'name': "LoginInd"})
    }

  },

};
</script>

<style scoped>
.rside {
  background-image: url("../../assets/bg_circle.jpg");
  background-size: cover;
  height: 100vh;
}
</style>

