<template>
  <div class="main-bg text-center">
    <div
      class="container"
      style="padding-bottom: 10px; padding-top: 80px; text-align: center"
    >
      <div class="" style="text-align: center">
        <div>
          <img
            v-if="userMe"
            :src="`${baseUrl}/${userMe.user.photo}`"
            :key="userMe.user.photo"
            style="
              width: 80px;
              height: 80px;
              border-radius: 45%;
              border: #002976 3px solid;
            "
            alt=""
          />
        </div>
        <br />
        <div class="">
          <h4
            v-if="userMe"
            style="font-size: 18px; margin-top: 15px; margin-left: 5px"
          >
            <span>{{ userMe.user.name || "-" }}</span>
          </h4>
          <div class="sColor" style="margin-bottom: 20px" v-if="userMe">
            <img
              style="margin-bottom: 3px"
              src="@/assets/mobile.svg"
              alt=""
            />+995
            {{ mainMemberPhone }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="mColor container"
      style="
        padding-top: 15px;
        padding-bottom: 30px;
        border-top: 1px solid rgba(198, 206, 221, 0.1);
        /* text-align: center; */
      "
    >
      <router-link
        id=""
        style="color: #e6e6e6; text-decoration: none"
        :to="{ name: 'Details', params: {} }"
      >
        <div class="row justify-content-center" style="text-align: left">
          <p
            class="col-lg-5 col-xl-5 mb-1"
            style="
              background: rgba(198, 206, 221, 0.2);
              padding: 12px;
              border-radius: 10px;
            "
          >
            {{ $t("profilePage.pd") }}
            <img src="@/assets/arright.svg" style="float: right" alt="" />
          </p>
        </div>
      </router-link>
      <router-link
        id=""
        style="color: #e6e6e6; text-decoration: none"
        :to="{ name: 'Language', params: {} }"
      >
        <div class="row justify-content-center" style="text-align: left">
          <p
            class="col-lg-5 col-xl-5 mb-1"
            style="
              background: rgba(198, 206, 221, 0.2);
              padding: 12px;
              border-radius: 10px;
            "
          >
            {{ $t("profilePage.lan") }}
            <img src="@/assets/arright.svg" style="float: right" alt="" />
          </p>
        </div>
      </router-link>
      <router-link
        id=""
        style="color: #e6e6e6; text-decoration: none"
        :to="{ name: 'Faq', params: {} }"
      >
        <div class="row justify-content-center" style="text-align: left">
          <p
            class="col-lg-5 col-xl-5 mb-1"
            style="
              background: rgba(198, 206, 221, 0.2);
              padding: 12px;
              border-radius: 10px;
            "
          >
            {{ $t("profilePage.faq") }}
            <img src="@/assets/arright.svg" style="float: right" alt="" />
          </p>
        </div> </router-link
      ><router-link
        id=""
        style="color: #e6e6e6; text-decoration: none"
        :to="{ name: 'Terms', params: {} }"
      >
        <div class="row justify-content-center" style="text-align: left">
          <p
            class="col-lg-5 col-xl-5 mb-1"
            style="
              background: rgba(198, 206, 221, 0.2);
              padding: 12px;
              border-radius: 10px;
            "
          >
            {{ $t("profilePage.tc") }}
            <img src="@/assets/arright.svg" style="float: right" alt="" />
          </p>
        </div> </router-link
      ><router-link
        id=""
        style="color: #e6e6e6; text-decoration: none"
        :to="{ name: 'Privacy', params: {} }"
      >
        <div class="row justify-content-center" style="text-align: left">
          <p
            class="col-lg-5 col-xl-5 mb-1"
            style="
              background: rgba(198, 206, 221, 0.2);
              padding: 12px;
              border-radius: 10px;
            "
          >
            {{ $t("landingPage.privacy") }}
            <img src="@/assets/arright.svg" style="float: right" alt="" />
          </p>
        </div>
      </router-link>
    </div>

    <button
      data-bs-toggle="modal"
      data-bs-target="#exitModal"
      style="
        margin-top: 30px;
        font-size: 16px;
        background: rgba(198, 206, 221, 0.2);
        border: none;
        color: white;
        padding: 12px;
        border-radius: 10px;
      "
    >
      <img src="@/assets/logout.svg" style="" alt="" />
      {{ $t("profilePage.logout") }}
    </button>

    <div class="modal" ref="exitModal" id="exitModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="modal-body"
            style="
              color: white;
              background: #071d42;
              padding: 50px;
              font-weight: bold;
              text-align: center;
            "
          >
            <img
              src="@/assets/logout.svg"
              style="width: 40px; margin-top: 40px; margin-bottom: 40px"
              alt=""
            />
            <p @click="logout" style="cursor: pointer">Exit</p>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal
      size="md"
      ref="remove-modal"
      centered
      id="remove-modal"
      hide-footer
      hide-header
      body-class="loginModal"
      footer-border-variant="none"
    >
      <div style="text-align: center; color: white; padding: 10px">
        <p>Are you sure, you want to logout?</p>
      </div>
      <div class="d-block">
        <b-button
          style="margin-bottom: 20px"
          class="mButton col"
          @click="logout"
          >{{ $t("profilePage.logout") }}
        </b-button>
        <br />
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { baseUrl } from "../../repository/repository";

export default {
  name: "ProfilePage",
  data() {
    return {
      baseUrl: baseUrl,
      personalIdPhones: [],
      token: "",
      reNumber: "",
      mainNum: 0,
    };
  },

  async mounted() {
    this.userProfile(localStorage.getItem("token"));

    this.token = localStorage.getItem("token");
  },

  methods: {
    ...mapActions(["userProfile"]),

    logout() {
      localStorage.removeItem("token");
      document.location.href = "/";
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["userMe"]),

    mainMemberPhone() {
      let number = "";

      if (this.userMe && this.userMe.members) {
        this.userMe.members.forEach((m) => {
          if (m.isMain === "true") {
            number = m.phone;
          }
        });
      }

      return number;
    },
  },
  watch: {
    userMe() {},
  },
};
</script>

<style scoped>
.logout {
  background-image: url(../../assets/logoutG.svg);
  background-size: cover;
}


</style>
