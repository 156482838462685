<template>
  <div class="main-bg">
  <div class="container" style="height: 100vh; padding-top: 60px">
    <b-button
      @click="$router.go(-1)"
      style="background: none; border: none; margin: 0; padding-top: 50px"
      ><img style="margin-right: 10px" src="@/assets/back.svg" alt="" />
      <span>{{ $t("profilePage.faq") }}</span>
    </b-button>
    <p
      class="sColor"
      style="margin-top: 35px; margin-bottom: 55px; font-size: 16px"
    >
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facilis optio
      amet corporis, non aliquid provident suscipit ad quidem.
    </p>

    <div
      class="accordion mb-2"
      id="accordionExample"
      v-for="(item, i) in 4"
      :key="i"
    >
      <div
        class="accordion-item"
        style="background-color: rgba(198, 206, 221, 0.2)"
      >
        <h2 class="accordion-header" id="heading">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + i"
            aria-expanded="false"
            :aria-controls="'collapse' + i"
          >
            Accordion Item {{ i + 1 }}
          </button>
        </h2>
        <div
          :id="'collapse' + i"
          class="accordion-collapse collapse"
          aria-labelledby="heading"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            {{ i }}
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae
            mollitia totam beatae. Minus accusantium beatae optio voluptates
            tempore eligendi non, soluta cupiditate esse rerum error sapiente
            deserunt itaque ipsum iure!
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col mColor">
      <div class="mb-3" v-for="(cat, i) in 4" :key="i" style="">
        <div style="padding: 15px; background: #0a294e; border-radius: 15px">
          <h4
            class=""
            style="font-size: 16px; font-weight: bold"
            @click="(cl = !cl), (dropImage = i)"
            v-b-toggle="'collapse-' + i"
          >
            Text from api
            <img
              style="float: right"
              :src="cl && i == dropImage ? source2 : source1"
            />
          </h4>
        </div>
        <b-collapse :id="'collapse-' + i" class="pt-2">
          <div style="padding: 20px; background: #0288d1; border-radius: 15px">
            <p style="padding-bottom: 0px; margin-bottom: 0px">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
              obcaecati iusto autem dolore Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Dignissimos voluptatibus
              exercitationem nemo quaerat magni qui, voluptatem aliquid sapiente
              placeat, eius error quibusdam suscipit aperiam velit cupiditate.
              Qui molestias natus veniam!
            </p>
          </div>
        </b-collapse>
      </div>
    </div> -->
  </div>
  </div>
</template>

<script>
export default {
  name: "FaqPage",
  data() {
    return {
      dropImage: "",
      cl: false,
      source1: require("@/assets/drpd.svg"),
      source2: require("@/assets/drpup.svg"),
    };
  },
  async mounted() {},

  methods: {},

  components: {},
  props: {},

  computed: {},
};
</script>

<style>
.accordion-button {
  background-color: rgba(255, 255, 255, 0.019) !important;
  color: white;
  border: none !important;
}
</style>
