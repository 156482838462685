import { createStore } from 'vuex'
import { userMe } from "../repository/DataRepository";

export default createStore({
  actions: {
    userProfile({ commit }, val) {
      userMe(val).then((res) => {
        const userMe = res.data.data;
        commit('userData', userMe);
        return userMe;
      });
    },
  },
  mutations: {
    userData: (state, userMe) => state.userMe = userMe,

  },
  state: {
    cookiesObject: document.cookie.split(';').map(cookie => cookie.split('=')).reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {}),
    userMe: null,
  },
  getters: {
    cookiesObject: (state) => state.cookiesObject,
    userMe: (state) => state.userMe,
  },
})
