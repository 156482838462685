<template>
  <div class="main-bg">
  <div class="container" style="height: 100vh">
    <button
      @click="$router.go(-1)"
      style="
        background: none;
        border: none;
        margin: 0;
        padding-top: 50px;
        color: white;
      "
    >
      <img style="margin-right: 10px" src="@/assets/back.svg" alt="" />
      <span>{{ $t("profilePage.lan") }}</span>
    </button>
    <p
      class="sColor"
      style="margin-top: 35px; margin-bottom: 55px; font-size: 16px"
    >
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facilis optio
      amet corporis, non aliquid provident suscipit ad quidem.
    </p>

    <div
      v-for="(language, i) in languages"
      :key="i"
      class="form-check col-lg-1 col-xl-1 col-md-2"
    >
      <input
        v-model="selLang"
        v-bind:true-value="language.id"
        class="form-check-input"
        type="checkbox"
        :value="language.id"
        id="flexCheckDefault"
        @change="($i18n.locale = language.code), setLang(selLang, token)"
        :checked="selLang == language.code ? true : false"
      />
      <label class="form-check-label" for="flexCheckDefault">
        {{ language.name }}
      </label>
    </div>
  </div>
  </div>
</template>

<script>
import { generalLanguages, setUserLang } from "../../repository/DataRepository";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LanguagePage",
  data() {
    return {
      language: "en",
      languages: [],
      selLang: "",
      token: "",
    };
  },
  async mounted() {
    this.userProfile(localStorage.getItem("token"));
    this.languageList();
    this.token = localStorage.getItem("token");
    if (this.userMe.user) {
      this.selLang = this.userMe.user.language.id;
    } else if (this.userMe.company) {
      this.selLang = this.userMe.company.language.id;
    }
  },

  methods: {
    ...mapActions(["userProfile"]),
    languageList() {
      generalLanguages().then((res) => {
        this.languages = res.data.languages;
      });
    },
    setLang(val1, val2) {
      setUserLang(val1, val2).then(() => {});
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["userMe"]),
  },

  watch: {
    userMe() {
      if (this.userMe.user) {
        this.selLang = this.userMe.user.language.id;
        this.$i18n.locale = this.userMe.user.language.code;
      } else if (this.userMe.company) {
        this.selLang = this.userMe.company.language.id;
        this.$i18n.locale = this.userMe.company.language.code;
      }
    },
  },
};
</script>

<style scoped>
.pp {
  text-align: center;
  margin-top: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  font-size: 14px;
  border-top: 1px solid rgba(236, 236, 236, 0.1);
}
</style>
