<template>


  <div class="main-bg">

    <audio style="display: none"
           ref="audioSuccess"
           src="/success.mp3"
           preload
           id="audio"
    ></audio>
    <audio style="display: none"
           ref="audioSpin"
           src="/spin.mp3"
           preload
           loop
           id="audio"
    ></audio>

    <div class="container py-5" style="max-width: 500px;">

      <div v-if="winnedPrize" class="text-white text-center d-flex align-items-center" style="min-height: 75vh;">

        <div v-if="alreadySpinned">
          <img src="../../assets/congrats.png" alt="-" class="mb-3 w-75"/>

          <h1>{{ $t('wof.alreadySpinned.title') }}</h1>
          <p>{{ $t('wof.alreadySpinned.body') }} </p>
        </div>
        <div v-else>

          <div style="position: absolute; left: 0; top: 70px; right: 0; bottom: 0;  z-index: 900">
            <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_6xsxyz57.json" background="transparent"
                           speed="1" style="width: 100%; height: 100%;" loop autoplay></lottie-player>
          </div>
          <div style="z-index: 1000; position: relative">
            <img src="../../assets/congrats.png" alt="-" class="mb-3 w-75"/>

            <div v-if="winnedPrize.is_purchasable">
              <h1>{{ $t('wof.success.package.title') }} </h1>
              <p>{{ $t('wof.success.package.body', {name: winnedPrize.name}) }}</p>
            </div>
            <div v-else>
              <h1>{{ $t('wof.success.bonus.title', {name: winnedPrize.name}) }} </h1>
              <p>{{ $t('wof.success.bonus.body') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="prizeList.length > 0">
          <div class="vo-wheel" :style="{ transform: 'rotate('+ rotationDegree+'deg)'}">
            <div v-for="(p,i) in prizeList" class="vo-wheel-slice"
                 :class="[`vo-wheel-slice-${i}`, highLightWin ?( i === winIndex?'vo-wheel-win':'vo-wheel-lose' ):'']" :key="p.id"
                 :style="{'background-image':`url(${baseUrl}/${p.photo})`}">
            </div>
            <div class="vo-wheel-center"></div>
          </div>

          <div class="my-4 text-center" v-if="!isSpinning">

            <button @click.prevent="spinTheWheel" class="mButton" style="
              font-size: 18px;
              margin-top: 20px;
              padding: 15px 70px 15px 70px;
            ">
              {{ $t("wof.spin") }}
            </button>
          </div>
        </div>
      </div>

      <div class="modal" ref="message1-modal" id="message1-modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div
                class="modal-body"
                style="
            color: white;
            background: #071d42;
            padding: 50px;
            font-weight: bold;
          "
            >
              <p>{{ statusResponse }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" ref="wof-initialization" id="wof-initialization" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div
                class="modal-body"
                style="
            color: white;
            background: #071d42;
            padding: 50px;
            font-weight: bold;
          "
            >
              <p>{{ $t('wof.initialization') }} </p>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import {Modal} from "bootstrap";
import {getWOFStatus, spinWOF} from "@/repository/DataRepository";
import {baseUrl} from "@/repository/repository";

export default {
  name: "NewWheel",
  data() {
    return {
      baseUrl: baseUrl,
      rotationDegree: 0,
      isSpinning: false,
      prizeList: [],
      tempPrize: null, //keeping prize from api then to set for winned prize
      winnedPrize: null,
      alreadySpinned: false,
      statusResponse: "",
      winIndex: -1,
      highLightWin: false,
      maxTurn: 0,
      errorModal: null,
      initModal: null,
      timer: null,
    };
  },
  async mounted() {
    this.getWofStatus();
    this.errorModal = new Modal(document.getElementById("message1-modal"));
    this.initModal = new Modal(document.getElementById("wof-initialization"));
  },

  methods: {
    getWofStatus() {


      getWOFStatus(localStorage.getItem("token")).then((response) => {
        if (response.code === 200) {
          this.prizeList = response.data.prizeList;
          this.tempPrize = response.data.prizeList[0];
        } else if (response.code === 201) {
          this.alreadySpinned = true;
          this.winnedPrize = response.data.prize;
        } else if (response.code === 401) {
          this.$router.push('/');
        } else {
          this.showError(response.message);

          setTimeout(() => {
            this.closeError();
            this.$router.push({name: 'HomePage'});
          }, 3000)
        }
      });
    },

    done() {
      this.$refs.audioSpin.pause();
      this.$refs.audioSuccess.play();
      this.highLightWin = true;
      clearTimeout(this.timer);
      setTimeout(() => {
        this.winnedPrize = this.tempPrize;
        this.$refs.audioSuccess.pause();
      }, 3000);

    },
    spinTheWheel() {
      if (!this.isSpinning) {
        this.initModal.show();


        spinWOF(localStorage.getItem("token")).then((res) => {
          this.initModal.hide();
          if (res.code === 200 || res.code === 201) {
            this.isSpinning = true;

            this.$refs.audioSpin.volume = 0.2;
            this.$refs.audioSpin.play();
            this.prizeList.forEach((p, i) => {
              if (p.id === res.data.prize.id) {
                this.maxTurn = 7 * 360 - i * 45 - 90 + 45 / 2;
                this.winIndex = i;
              }
            });

            this.tempPrize = res.data.prize;
            this.timer = setInterval(() => {
              if (this.rotationDegree >= this.maxTurn) {
                this.done()
              } else {

                let turn = 30;
                if (this.rotationDegree < 90 || this.rotationDegree >= this.maxTurn - 90) {
                  turn = 5;
                } else if (this.rotationDegree < 180 || this.rotationDegree >= this.maxTurn - 180) {
                  turn = 10;
                } else if (this.rotationDegree < 270 || this.rotationDegree >= this.maxTurn - 270) {
                  turn = 15;
                } else if (this.rotationDegree < 360 || this.rotationDegree >= this.maxTurn - 360) {
                  turn = 20;
                }
                this.rotationDegree += turn;
              }
            }, 100);
          } else {
            this.showError(res.message);
            setTimeout(() => {
              this.closeError();
            }, 3000)
          }
        });

      }
    },
    showGifts() {
      this.giftModal.show();
    },
    closeGiftModal() {
      this.giftModal.hide();
    },

    showError(text) {
      this.statusResponse = text;
      this.errorModal.show();
    },
    closeError() {
      this.errorModal.hide();
    }
  }
  ,
  props: {}
  ,

  computed: {
    ...
        mapGetters(["cookiesObject"]),
  }
  ,
}
;
</script>

<style lang="scss">

.vo-wheel {

  display: block;
  width: 500px;
  height: 500px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  border: 10px solid #ffffff;
  background-color: #3064ab;

  @media screen and (max-width: 550px){
    width: 450px;
    height: 450px;
  }

  @media screen and (max-width: 450px){
    width: 350px;
    height: 350px;
  }

  @media screen and (max-width: 350px){
    width: 300px;
    height: 300px;
  }

  @media screen and (max-width: 300px){
    width: 200px;
    height: 200px;
  }

  .vo-wheel-slice {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 50%;
    height: 35%;
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    transform-origin: bottom left;

    &.vo-wheel-lose {
      opacity: 0.2;
    }
  }

  @for $i from 0 through 7 {
    .vo-wheel-slice-#{$i} {
      transform: rotate($i * 45deg);
    }
  }

  .vo-wheel-center {
    background: url('../../assets/wheel-logo.png') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 35%;
    top: 35%;
    bottom: 35%;
    right: 35%;
  }


}

</style>

